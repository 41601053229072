exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-academicadvantage-js": () => import("./../../../src/pages/academicadvantage.js" /* webpackChunkName: "component---src-pages-academicadvantage-js" */),
  "component---src-pages-alwaysapril-js": () => import("./../../../src/pages/alwaysapril.js" /* webpackChunkName: "component---src-pages-alwaysapril-js" */),
  "component---src-pages-es-resultados-de-la-busqueda-js": () => import("./../../../src/pages/es/resultados-de-la-busqueda.js" /* webpackChunkName: "component---src-pages-es-resultados-de-la-busqueda-js" */),
  "component---src-pages-location-mustang-ok-js": () => import("./../../../src/pages/location/mustang-ok.js" /* webpackChunkName: "component---src-pages-location-mustang-ok-js" */),
  "component---src-pages-search-results-js": () => import("./../../../src/pages/search-results.js" /* webpackChunkName: "component---src-pages-search-results-js" */),
  "component---src-pages-secondchance-js": () => import("./../../../src/pages/secondchance.js" /* webpackChunkName: "component---src-pages-secondchance-js" */),
  "component---src-pages-terms-conditions-js": () => import("./../../../src/pages/terms-conditions.js" /* webpackChunkName: "component---src-pages-terms-conditions-js" */),
  "component---src-pages-w-4-w-application-js": () => import("./../../../src/pages/w4w-application.js" /* webpackChunkName: "component---src-pages-w-4-w-application-js" */),
  "component---src-pages-w-4-w-js": () => import("./../../../src/pages/w4w.js" /* webpackChunkName: "component---src-pages-w-4-w-js" */),
  "component---src-pages-w-4-w-step-1-js": () => import("./../../../src/pages/w4w-step-1.js" /* webpackChunkName: "component---src-pages-w-4-w-step-1-js" */),
  "component---src-pages-w-4-w-step-2-js": () => import("./../../../src/pages/w4w-step-2.js" /* webpackChunkName: "component---src-pages-w-4-w-step-2-js" */),
  "component---src-pages-wisdom-terms-conditions-js": () => import("./../../../src/pages/wisdom-terms-conditions.js" /* webpackChunkName: "component---src-pages-wisdom-terms-conditions-js" */),
  "component---src-templates-about-us-js": () => import("./../../../src/templates/about-us.js" /* webpackChunkName: "component---src-templates-about-us-js" */),
  "component---src-templates-anesthesia-options-js": () => import("./../../../src/templates/anesthesia-options.js" /* webpackChunkName: "component---src-templates-anesthesia-options-js" */),
  "component---src-templates-basic-page-js": () => import("./../../../src/templates/basic-page.js" /* webpackChunkName: "component---src-templates-basic-page-js" */),
  "component---src-templates-contact-us-js": () => import("./../../../src/templates/contact-us.js" /* webpackChunkName: "component---src-templates-contact-us-js" */),
  "component---src-templates-index-js": () => import("./../../../src/templates/index.js" /* webpackChunkName: "component---src-templates-index-js" */),
  "component---src-templates-patient-instructions-js": () => import("./../../../src/templates/patient-instructions.js" /* webpackChunkName: "component---src-templates-patient-instructions-js" */),
  "component---src-templates-patient-stories-js": () => import("./../../../src/templates/patient-stories.js" /* webpackChunkName: "component---src-templates-patient-stories-js" */),
  "component---src-templates-payment-plans-js": () => import("./../../../src/templates/payment-plans.js" /* webpackChunkName: "component---src-templates-payment-plans-js" */),
  "component---src-templates-privacy-policy-js": () => import("./../../../src/templates/privacyPolicy.js" /* webpackChunkName: "component---src-templates-privacy-policy-js" */),
  "component---src-templates-procedures-js": () => import("./../../../src/templates/procedures.js" /* webpackChunkName: "component---src-templates-procedures-js" */),
  "component---src-templates-single-doctor-js": () => import("./../../../src/templates/singleDoctor.js" /* webpackChunkName: "component---src-templates-single-doctor-js" */),
  "component---src-templates-single-instruction-js": () => import("./../../../src/templates/singleInstruction.js" /* webpackChunkName: "component---src-templates-single-instruction-js" */),
  "component---src-templates-single-procedure-js": () => import("./../../../src/templates/singleProcedure.js" /* webpackChunkName: "component---src-templates-single-procedure-js" */),
  "component---src-templates-single-review-js": () => import("./../../../src/templates/singleReview.js" /* webpackChunkName: "component---src-templates-single-review-js" */),
  "component---src-templates-three-d-imaging-js": () => import("./../../../src/templates/three-d-imaging.js" /* webpackChunkName: "component---src-templates-three-d-imaging-js" */),
  "component---src-templates-your-first-visit-js": () => import("./../../../src/templates/your-first-visit.js" /* webpackChunkName: "component---src-templates-your-first-visit-js" */)
}

