module.exports = [{
      plugin: require('../node_modules/gatsby-plugin-netlify-cms/gatsby-browser.js'),
      options: {"plugins":[],"manualInit":true,"modulePath":"D:\\gatsby\\gatsby-okc/src/cms/cms.js","enableIdentityWidget":false,"htmlFavicon":"src/images/favicon-512x512.png"},
    },{
      plugin: require('../node_modules/gatsby-plugin-canonical-urls/gatsby-browser.js'),
      options: {"plugins":[],"siteUrl":"https://www.oklahomacityoralsurgery.com/"},
    },{
      plugin: require('../node_modules/gatsby-plugin-manifest/gatsby-browser.js'),
      options: {"plugins":[],"name":"Oral Surgery Specialists of Oklahoma Dental Implants Experts","lang":"en","display":"standalone","short_name":"Oral Surgery Specialists of Oklahoma Dental Implants Experts","description":"Our office in Oklahoma City, OK, offers patients wisdom teeth removals, dental implants, and other oral surgery procedures in a warm and welcoming environment.","start_url":"/","background_color":"#eafcfb","theme_color":"#0756a6","icon":"src/images/favicon-512x512.png","crossOrigin":"use-credentials","legacy":true,"theme_color_in_head":true,"cache_busting_mode":"query","include_favicon":true,"cacheDigest":"0cc9cecc023fa2d6cb39a581366c838a"},
    },{
      plugin: require('../node_modules/gatsby-plugin-catch-links/gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../node_modules/gatsby-plugin-google-tagmanager/gatsby-browser.js'),
      options: {"plugins":[],"id":"GTM-NDDJ7JM","includeInDevelopment":false,"defaultDataLayer":{"type":"object","value":{"platform":"gatsby"}},"routeChangeEventName":"gatsby-route-change","enableWebVitalsTracking":false,"selfHostedOrigin":"https://www.googletagmanager.com"},
    },{
      plugin: require('../gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../node_modules/gatsby/dist/internal-plugins/partytown/gatsby-browser.js'),
      options: {"plugins":[]},
    }]
